<script>
import isNil from 'lodash/isNil';
import each from 'lodash/each';
import delay from 'lodash/delay';
import { mapState } from 'vuex';
import {
  DATE_FORMAT, DELAY, FALLBACK_MESSAGE, formatSpeed, formatStopWatch,
  formatUtc, TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import googleMapMixin from '@/components/GoogleMap/googleMapMixin';
import { GOOGLE_MAP_STYLES } from '@/components/GoogleMap/const/googleMapStyle.const';
import { mileageUnitMixin } from '@/domains/Carrental/mixins';

export default {
  name: 'GoogleMapTripsComponent',
  mixins: [googleMapMixin, mileageUnitMixin],
  props: {
    beforePath: {
      type: Array,
      default: () => [],
    },
    afterPath: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      infowindow: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
  },
  watch: {
    markerSources() {
      each(this.markers, item => item.setMap(null));
      this.markers = [];
      this.renderMap();
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.TIME_ZONE = TIME_ZONE;
  },
  methods: {
    initGoogle() {
      this.googleInstance = this.$GoogleMaps.getInstance();
      this.bounds = new this.googleInstance.maps.LatLngBounds();
      const element = this.$refs[`gMap${this.mapId}`];
      this.map = new this.googleInstance.maps.Map(element, {
        gestureHandling: this.gestureHandling,
        fullscreenControl: this.fullScreenControl,
        streetViewControl: this.streetViewControl,
        mapTypeControl: this.mapTypeControl,
        zoomControl: this.zoomControl,
        styles: GOOGLE_MAP_STYLES,
      });
      this.map.fitBounds(this.bounds);
    },
    drawPolyline(points, color) {
      return new this.googleInstance.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
    },
    async renderMap() {
      if (!this.markerSources) {
        return;
      }
      await this.markerSources.forEach(this.processMarker);

      if (this.beforePath.length) {
        const beforePolyline = this.drawPolyline(this.beforePath, '#059142');
        beforePolyline.setMap(this.map);
      }

      if (this.afterPath.length) {
        const afterPolyline = this.drawPolyline(this.afterPath, '#ff0000');
        afterPolyline.setMap(this.map);
      }

      this.map.fitBounds(this.bounds);
    },
    getTooltipTemplate(marker) {
      if (!marker.timestamp && !marker.millisecondsDuration) {
        return `<p>${marker.tooltip || FALLBACK_MESSAGE.dash}</p>`;
      }
      return marker.millisecondsDuration
        ? `<p>Date/time: ${formatUtc(marker.timestamp, DATE_FORMAT.defaultExtended, this.operatorTimezone)}</p>
           <p>Position: ${marker.position}</p>
           <p>Duration: ${formatStopWatch(marker.millisecondsDuration / 1000)}</p>
           <p>Speed: ${formatSpeed(marker.speed || 0, this.mileageUnit, 0)}</p>`
        : `<p>Date/time: ${formatUtc(marker.timestamp, DATE_FORMAT.defaultExtended, this.operatorTimezone)}</p>
           <p>Position: ${marker.position}</p>
           <p>Speed: ${formatSpeed(marker.speed || 0, this.mileageUnit, 0)}</p>`;
    },
    addLocation(location) {
      const marker = new this.googleInstance.maps.Marker(location);
      marker.info = new this.googleInstance.maps.InfoWindow({
        content: this.getTooltipTemplate(marker),
      });

      this.googleInstance.maps.event.addListener(marker, 'mouseover', () => {
        marker.info.open(this.map, marker);
      });

      this.googleInstance.maps.event.addListener(marker, 'mouseout', () => {
        marker.info.close(this.map, marker);
      });

      marker.setMap(this.map);

      this.markers.push(marker);
    },
    processMarker({
      latitude,
      longitude,
      icon,
      uuid,
      tooltip,
      zIndex,
      timestamp,
      millisecondsDuration,
      speed,
    }) {
      if (isNil(latitude) || isNil(longitude)) {
        return;
      }

      const position = { lat: latitude, lng: longitude };
      const marker = {
        position,
        icon,
        uuid,
        tooltip,
        zIndex,
        timestamp,
        millisecondsDuration,
        speed,
      };

      this.addLocation(marker);
      this.bounds.extend(position);
      this.map.fitBounds(this.bounds);
    },
    setZoom(zoom) {
      delay(() => {
        this.map.setZoom(zoom);
      }, DELAY.tiny);
    },
  },
};
</script>

<template>
  <div class="GoogleMapComponent">
    <div
      :ref="`gMap${mapId}`"
      :class="[
        'GoogleMapComponent__holder',
        { 'GoogleMapComponent__holder--full-height': fullHeight }
      ]"
    >
      `gMap${mapId}`
    </div>
  </div>
</template>
