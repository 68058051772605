var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "GoogleMapComponent" }, [
    _c(
      "div",
      {
        ref: `gMap${_vm.mapId}`,
        class: [
          "GoogleMapComponent__holder",
          { "GoogleMapComponent__holder--full-height": _vm.fullHeight },
        ],
      },
      [_vm._v(" `gMap${mapId}` ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }